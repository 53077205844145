import React, { Component } from 'react';

import API from '../../api/api';
import { showAboutMe, showContactForms, showContactInfo, showMusicLinks, showTour, submitSuccess } from '../../menuChooser/menuChooser';
import Backdrop from '../UI/Backdrop/Backdrop';

class Contact extends Component {
    state = {
        windowWidth: 1080,
        windowHeight: 720,
        showBackdrop: false,
        content: null,
        contactForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'NAME:                           your real name...'
                },
                value: undefined
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'EMAIL:            not-your-spam-mail@realmail.com'
                },
                value: undefined
            },
            tlf: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'TLF:                                    +12345678'
                },
                value: undefined
            },
            city: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'CITY:    concert near you? what\'s your address?'
                },
                value: undefined
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'COUNTRY:                            '
                },
                value: undefined
            }
        }
    }

    componentDidMount() {
        this.setState({ windowWidth: window.innerHeight });
        this.setState({ windowHeight: window.innerWidth });
        this.showContactForms();
    }

    toggleBackdrop = () => {
        if (this.state.showBackdrop) {
            this.setState({ showBackdrop: !this.state.showBackdrop });
            console.log('[Layout.js]: showBackdrop ', this.state.showBackdrop);
        }
    }

    contactHandler = async (e) => {
        e.preventDefault()
        const formData = {};
        console.log(this.state.contactForm)
        for (let formElementIdentifier in this.state.contactForm) {
            formData[formElementIdentifier] = this.state.contactForm[formElementIdentifier].value
        }
        console.log(formData)
        try {
            const reply = await API.post('/contact/', formData)
            console.log("REPLY: ", reply)
            if (reply.status === 201 && Object.keys(reply.data).length > 0) {
                const success = submitSuccess();
                this.setState({ content: success });
            }

            this.setState({ loading: false });
        } catch (e) {
            console.log(e)
            this.setState({ loading: false });
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        console.log(event.target.value);
        const updatedContactForm = {
            ...this.state.contactForm
        }

        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        }

        updatedFormElement.value = event.target.value;
        updatedContactForm[inputIdentifier] = updatedFormElement;
        this.setState({ contactForm: updatedContactForm })
    }

    showContactForms = () => {
        const formElementsArray = [];
        for (let key in this.state.contactForm) {
            formElementsArray.push({
                id: key,
                config: this.state.contactForm[key]
            });
        }

        const contactForms = showContactForms(formElementsArray, this.inputChangedHandler, this.contactHandler)
        this.setState({ content: contactForms });
        if (!this.state.showBackdrop) {
            this.setState({ showBackdrop: !this.state.showBackdrop });
        }
    }
    render() {
        return (
            <Backdrop
                show={true}
                clicked={true}>{this.state.content}
            </Backdrop>
        );
    }
}

export default Contact;