import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/images/petahMoodieLogo.png';
import classes from './Logo.module.css';

const logo = (props) => (
    <div
    className={classes.Logo}
    onClick={props.clicked}
    ><Link to="/representation"> <img src={Logo} alt="Logo"></img> </Link></div>
);

export default logo;