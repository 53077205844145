import React from 'react';

import classes from './Backdrop.module.css';

// onClick={props.clicked}
const backdrop = ( props ) => {
    const aggregatedClasses = [classes.fadeIn, classes.Backdrop];
    return (
    props.show ? <div 
    className={aggregatedClasses.join(' ')}
    >{props.children}</div> : null
)
    }

export default backdrop;