import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';

import classes from './responsive-player.module.css';
import computerVideo from '../../../assets/videos/homepage_industrial_computer.mp4';
import mobileVideo from '../../../assets/videos/homepage_industrial_phone_480p.mp4';

class ResponsivePlayer extends Component {

  state = {
    isVideoLoaded: false
  }

  componentDidUpdate() {
    // console.log("[ComponentDidUpdate]: isVideoLoaded " + this.state.isVideoLoaded)
  }

  onLoadedData = () => {
    if (!this.state.isVideoLoaded) {
      this.setState({ isVideoLoaded: true });
    }
  };

  getVideoSrc = (width) => {
    if (width >= 1080) {
      return computerVideo;
    }
    return mobileVideo;
  }

  render() {
    // const thumbClass = [classes.video_thumb, classes.tiny];

    return (
      <section className={classes.playerWrapper}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={this.getVideoSrc(window.innerWidth)}
          onLoadedData={() => this.onLoadedData()}
          height='100%'
          width='100%'
          playing
          autoPlay
          playsinline
          muted
          loop
        />
      </section>

    )
  }
}

export default ResponsivePlayer;