import React from 'react';

import classes from './SideMenu.module.css';
import SideItem from './SideItem/SideItem';

const links = {
    home: "/",
    about: "/about-me",
    music: "/music",
    tour: "/tour",
    contact: "/contact",
}


const sideMenu = (props) => {

    let showActions = {}
    Object.keys(props).map(menuFunction => showActions = {...showActions, [menuFunction]: props[menuFunction]})
    const content = Object.keys(links).map((name) => {
        return <SideItem key={name} clicked={showActions[name]} link={links[name]} image={null}> {name} </SideItem>
    })

    return (
        <div className={classes.SideMenu}>
            <ul>
                {content}
            </ul>
        </div>
    )
};

export default sideMenu;