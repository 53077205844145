import React from 'react';
import { Link } from 'react-router-dom';
import classes from './SideItem.module.css';

const sideItem = (props) => (
    <li className={classes.SideItem}>
           <p> <Link to={props.link} onClick={props.clicked} className={classes.links}>
                {props.children}
            </Link></p>
    </li>
)

export default sideItem;