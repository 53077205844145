
import TextDisplay from '../../components/UI/TextDisplay/TextDisplay';
import Backdrop from '../UI/Backdrop/Backdrop';
import classes from '../../components/UI/TextDisplay/TextDisplay.module.css';
import API from '../../api/api';
import React from 'react'

import { useState, useEffect } from 'react';

var previousYear = new Date().getFullYear()
const formatEntry = (index, Entry, category) => {
    if (index === 0 && category === 'shows') {
        return [
            <>
                <h4>Tour Announcement {Entry.year}</h4>
                <h5><strong>{Entry.date}</strong></h5>
                <a href={Entry.ticket}>
                    <p>{Entry.venue}, {Entry.location}</p>
                </a>
            </>
        ]
    } else if (index === 0 && category === 'Activity') {
        return [
            <>
                <h4>Activity {Entry.year}</h4>
                <h5><strong>{Entry.date}</strong></h5>
                <a href={Entry.ticket}>
                    <p>{Entry.description}, {Entry.location}</p>
                </a>
            </>
        ]
    }
    else if (index !== 0 && category === 'Activity') {
        return <>
            <h5><strong>{Entry.date}</strong></h5>
            <a href={Entry.link}>
                <p>{Entry.description}, {Entry.location}</p>
            </a>
        </>
    }
    else {
        return <>
            <h5><strong>{Entry.date}</strong></h5>
            <a href={Entry.link}>
                <p>{Entry.venue}, {Entry.location}</p>
            </a>
        </>
    }

}

const Tour = () => {
    // const { Tours } = useScript("//widget.songkick.com/10143232/widget.js", "TOURS");
    const [content, setContent] = useState(undefined)
    const [tourData, setTourData] = useState(undefined)
    const [currentYear, setYear] = useState(previousYear)
    const [yearList, setYearList] = useState([])

    useEffect(() => {
        const fetchTour = async () => {
            try {
                const results = await API.get('/tourData/')
                console.log("Requesting tour data: ", results.data)
                console.log("just checking if updated...")
                setTourData(results.data)
            } catch (e) {
                console.log("failed to get tour details...")
            }
        }
        fetchTour()
    }, [])

    useEffect(() => {
        if (currentYear !== previousYear) {
            updateTour();
        }
    }, [currentYear])

    const sortShows = (shows, year, category) => {
        var sorted = []
        var years = new Set()
        try {
            shows.forEach(show => {
                years.add(show.year)
                if (sorted.length === 0 && show.year === year) {
                    sorted = formatEntry(0, show, category)
                }
                else if (show.year === year) {
                    sorted.push(
                        formatEntry(1, show, category)
                    )
                }
            })
            setYearList(years)
            return sorted
        } catch (e) {
            console.log(e)
        }
    }

    const updateTour = () => {
        const displayShows = sortShows(tourData[1], currentYear, 'shows')
        const displayActivity = sortShows(tourData[0], currentYear, 'Activity')
        setContent(
            <TextDisplay>
                <div>{displayShows}</div>
                <div>{displayActivity}</div>
            </TextDisplay>)
    }

    const updateYear = (year) => {
        previousYear = currentYear;
        setYear(year);
        updateTour();
    }

    if (!content && tourData) {
        updateTour();
    }

    var keys = []
    yearList.forEach(year =>
        keys.push(<>
            <p className={classes.years} onClick={() => updateYear(year)}>
                {year}
            </p>
        </>)
    )

    return (
        <Backdrop
            show={true}
            clicked={true}>{content}{keys}
        </Backdrop>
    )
}

export default Tour;