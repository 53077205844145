import React from 'react';

const navigationItem = (props) => (     
    <li className={props.classes.NavigationItem}>
            <a href={props.link}>
                <img onMouseOver={e => (e.currentTarget.src = props.image2)} 
                onMouseOut={e => (e.currentTarget.src = props.image)} 
                src={props.image} alt='img'/>
                {props.children}
            </a>
    </li>
)

export default navigationItem;