import TextDisplay from '../../components/UI/TextDisplay/TextDisplay';
import Backdrop from '../UI/Backdrop/Backdrop';
import classes from '../../components/UI/TextDisplay/TextDisplay.module.css';

const Representation = () => {
    const contactInfo = (
        <TextDisplay>
            <div className={classes.gridItem}>
                <h4>NAME</h4>
                <p>Petah Moodie</p>
            </div>
            <div className={classes.gridItem}>
                <h4>EMAIL</h4>
                <p>petahmoodie@gmail.com</p>
            </div>
            <div className={classes.gridItem}>
                <h4>MANAGER</h4>
                <p>petahmoodie@gmail.com</p>
            </div>
            <div className={classes.gridItem}>
                <h4>BOOKER</h4>
                <p>petahmoodie@gmail.com</p>
            </div>
        </TextDisplay>
    )

    return (
        <Backdrop
            show={true}
            clicked={true}>{contactInfo}
        </Backdrop>
    )
}

export default Representation;