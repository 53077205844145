import React, { Component } from 'react';

import Layout from '../components/Layout/Layout';

class MoodChanger extends Component {
    state = {
        windowWidth: 1080,
        windowHeight: 720,
        showBackdrop: false,
        content: null,
        contactForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'NAME:                           your real name...'
                },
                value: undefined
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'EMAIL:            not-your-spam-mail@realmail.com'
                },
                value: undefined
            },
            tlf: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'TLF:                                    +12345678'
                },
                value: undefined
            },
            street: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'STREET:    concert near you? what\'s your address?'
                },
                value: undefined
            },
            zipcode: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'ZIP:                                     zip code'
                },
                value: undefined
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'COUNTRY:                            '
                },
                value: undefined
            }
        }
    }

    componentDidMount() {
        this.setState({ windowWidth: window.innerHeight });
        this.setState({ windowHeight: window.innerWidth });
    }

    toggleBackdrop = () => {
        if (this.state.showBackdrop) {
            this.setState({ showBackdrop: !this.state.showBackdrop });
            console.log('[Layout.js]: showBackdrop ', this.state.showBackdrop);
        }
    }


    render() {
        return (
            <Layout
                {...this.props}
                // contact={() => this.showContactForms()}
                // music={() => this.showMusicLinks()}
                // about={() => this.showAboutMe()}
                // tour={() => this.showTour()}
                home={() => this.toggleBackdrop()}
                show={this.state.showBackdrop}
                toggle={() => this.toggleBackdrop()}
                // contactInfo={() => this.showContactInfo()}
                content={this.state.content}
                height={this.state.innerHeight}
                width={this.state.innerWidth}
            ></Layout>
        );
    }
}

export default MoodChanger;