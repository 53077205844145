import Backdrop from '../UI/Backdrop/Backdrop';
import classes from '../../components/UI/TextDisplay/TextDisplay.module.css';

import hp1 from '../../assets/images/HP1.png';
import hp2 from '../../assets/images/HP2.png';
import hp3 from '../../assets/images/HP3.png';
import hp4 from '../../assets/images/HP4.png';
import hp5 from '../../assets/images/HP5.png';
import hp6 from '../../assets/images/HP6.png';
import hp7 from '../../assets/images/HP7.png';
import hp8 from '../../assets/images/HP8.png';


const About = () => {
    const aboutInfo = (
        <div className={classes.boxDisplay}>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp8} alt="silhouette2"></img>
                    <p>“I just want to be the soundtrack to someone’s moment” - Petah Moodie
                        <br></br>
                        <br></br>
                        I make cloudy, synth-based r&b and future-soul in my bedroom but people
                        describe it as “A warm hug on a cold winter day.”
                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp1} alt="passports"></img>
                    <p> Growing up in the ghetto in Kingston, Jamaica, I was immersed in music blasting through massive home-made sound systems stacked-up everywhere you went.
                        My relationship to music really changed in 6th grade when I got a recorder flute in our basic music class.
                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp5} alt="koncert"></img>
                    <p>I was worried my parents couldn’t afford the
                        flute. It taught me that I too can create music. I started transcribing
                        basic songs back then and always had a wish to do music ever since.
                        Moving to Scandinavia brought an electronic element into my music.
                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp4} alt="outdoors"></img>
                    <p>
                        Focussing on school opened doors for me and I ended up in Sweden.
                        In Sweden, I found the opportunity to get my own computer (a big deal).
                        From there on, teaching myself to play, sing and produce.
                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp2} alt="outdoors"></img>
                    <p>
                        I want to make the soundtrack to an experience in your life. When you’re walking
                        down the street with a heartbreak or you just met the love of your life and you put
                        my music on to mirror or amplify that feeling. That’s the intention behind my
                        music, to be there for you in your highs and lows.

                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp6} alt="outdoors"></img>
                    <p>
                        I do this because music has been the soundtrack to many moments in my life.
                        Music has mirrored every feeling I have had, my big moments, my small moments
                        and I feel I want to give back.

                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp7} alt="outdoors"></img>
                    <p>
                        It would mean the world to me if someone else
                        could connect to a moment that I put down in music through my urge to create.
                        Knowing the impact that music has had on my life, it would be an honor if my
                        music would have that impact on somebody else’s life.
                    </p>
                </div>
            </div>
            <div className={classes.aboutParagraph}>
                <div>
                    <img className={classes.aboutMeImg} src={hp3} alt="outdoors"></img>
                    <p>
                        Thank your for being a part of this journey.
                    </p>
                </div>
            </div>
        </div>
    )

    return (
        <Backdrop
            show={true}
            clicked={true}>{aboutInfo}
        </Backdrop>
    )
}

export default About;