import React, { Component } from 'react';

import Aux from '../../hoc/Aux';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import ResponsivePlayer from '../UI/Video/ResponsivePlayer';
import Logo from '../UI/Logo/Logo';
import Backdrop from '../UI/Backdrop/Backdrop';
import SideMenu from '../Navigation/SideMenu/SideMenu';
import { Route } from 'react-router-dom';
import Music from '../Pages/Music';
import About from '../Pages/About';
import Contact from '../Pages/Contact';
import Tour from '../Pages/Tour';
import Rep from '../Pages/Representation';
import EPK from '../Pages/EPK';
class Layout extends Component {

    render() {
        return (
            <Aux>
                <Toolbar />
                <Logo clicked={this.props.contactInfo} />
                <ResponsivePlayer
                    width={this.props.width}
                    height={this.props.height} />
                <SideMenu {...this.props}
                    home={this.props.home} />
                <Backdrop
                    show={this.props.show}
                    clicked={this.props.toggle}>{this.props.content}
                </Backdrop>
                <Route path="/music">
                    <Music />
                </Route>
                <Route path="/about-me">
                    <About />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route path="/tour">
                    <Tour />
                </Route>
                <Route path="/representation">
                    <Rep />
                </Route>
                <Route path="/epk">
                    <EPK />
                </Route>
            </Aux>
        )
    }
}

export default Layout;