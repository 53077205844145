import Backdrop from '../UI/Backdrop/Backdrop';
import classes from '../../components/UI/TextDisplay/TextDisplay.module.css';
import TextDisplay from '../../components/UI/TextDisplay/TextDisplay';

const About = () => {
    const aboutInfo = (
        <TextDisplay>
            <div className={classes.gridItem}>
                <h4>Links to Reviews</h4>
                <div>
                    <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>

                    <p><a href="https://sverigesradio.se/artikel/7436771"> Sweden P3 Din Gata</a></p>

                    <p><a href="https://www.dr.dk/musik/karrierekanonen/petah-moodie-svoeber-dig-i-det-laekreste-rnb-taeppe"> Denmark karrierekanonen</a></p>

                    <p><a href="https://podcasts.apple.com/dk/podcast/ep-4-the-best-of-the-worst-year-ever/id1537963146?i=1000502800599">Haps Haps Concert Review, starts at 06:40</a></p>
                </div>
            </div>
            <div className={classes.gridItem}>
                <h4><strong>Unreleased Material</strong></h4>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">Hurricane</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">Prism</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">Unfortunately</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">Physical</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SpaceShips</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>
                <p><a href="https://www.soundkartell.de/review-petah-moodie/">SoundKartell Review</a></p>

Because of You
Phase
Live Performances
Running
Serenity
Concert showcase
Soundcheck
Music Videos
Prism
Hurricane
Go Slow
            </div>
        </TextDisplay>
    )

    return (
        <Backdrop
            show={true}
            clicked={true}>{aboutInfo}
        </Backdrop>
    )
}

export default About;