import React from 'react';

import NavigationItem from './NavigationItem/NavigationItem';
import spotify from '../../../assets/images/spotify_grey.png';
import youtube from '../../../assets/images/youtube_grey.png';
import tidal from '../../../assets/images/tidal_grey.png';
import applemusic from '../../../assets/images/applemusic_grey.png';
import soundcloud from '../../../assets/images/soundcloud_grey.png';
import instagram from '../../../assets/images/instagram_grey.png';
import spotify_red from '../../../assets/images/spotify_red.png';
import youtube_red from '../../../assets/images/youtube_red.png';
import tidal_red from '../../../assets/images/tidal_red.png';
import soundcloud_red from '../../../assets/images/soundcloud_red.png';
import instagram_red from '../../../assets/images/instagram_red.png';
import applemusic_red from '../../../assets/images/applemusic_red.png';
import fb from '../../../assets/images/fb_grey.png';
import fb_red from '../../../assets/images/fb_red.png';
import itunes from '../../../assets/images/itunes_grey.png';
import itunes_red from '../../../assets/images/itunes_red.png';

import classes from './NavigationItems.module.css';
import item_classes from './NavigationItem/NavigationItem.module.css';


const navigationItems = (props) => {

    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem
                link='https://music.apple.com/us/artist/petah-moodie/1218042091'
                image={applemusic}
                image2={applemusic_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://open.spotify.com/artist/0OefjbMJubDKsX94iSrun0?autoplay=true'
                image={spotify}
                image2={spotify_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://www.youtube.com/channel/UCpJQbvGwwHL5WanKKA8lrWQ'
                image={youtube}
                image2={youtube_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://tidal.com/browse/artist/8624002'
                image={tidal}
                image2={tidal_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://www.instagram.com/petahmoodie/'
                image={instagram}
                image2={instagram_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://www.facebook.com/petahmoodie'
                image={fb}
                image2={fb_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://itunes.apple.com/us/artist/petah-moodie/id1218042091'
                image={itunes}
                image2={itunes_red}
                classes={item_classes}>
            </NavigationItem>
            <NavigationItem
                link='https://soundcloud.com/petahmoodie'
                image={soundcloud}
                image2={soundcloud_red}
                classes={item_classes}>
            </NavigationItem>
        </ul>
    )
}

export default navigationItems;