import React from 'react';

import classes from './TextDisplay.module.css'
import Aux from '../../../hoc/Aux';

// let aggregatedClasses = [classes.TextDisplay, classes.box1];

const textDisplay = (props) => (
    <Aux>
        <div className={classes.box1}>
            <div className={classes.TextDisplay}>
                {props.children}
            </div>
        </div>
    </Aux>
);

export default textDisplay;