import Aux from '../hoc/Aux';
import Input from '../components/UI/Input/Input';
import TextDisplay from '../components/UI/TextDisplay/TextDisplay';
import classes from '../components/UI/TextDisplay/TextDisplay.module.css';
import logo from '../assets/images/petahMoodieLogo.png';

export function showTour() {
    const tourInfo = (
        <TextDisplay>
            <div className={classes.gridItem}>
                <h4>Tour Announcement</h4>
                <p>Coming soon...</p>
            </div>
        </TextDisplay>
    )
    return tourInfo
}

export function showContactForms(formElementsArray, inputChangedHandler, contactHandler) {
    const contactForms = <Aux>
        <TextDisplay>
            <form className={classes.form} onSubmit={contactHandler}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        changed={(event) => inputChangedHandler(event, formElement.id)}
                    />
                ))}
                <button>get in touch!</button>
            </form>
        </TextDisplay>
    </Aux>

    return contactForms
}

export function submitSuccess() {
    return (
        <TextDisplay>
            <div className={classes.success}>
                <img src={logo} alt="logo">
                </img>
                <p>
                    You are now a part of the <strong>MVMNT</strong>.
                    <br />
                    <br />
                    We'll stay in touch.
                </p>
            </div>
        </TextDisplay>
    )
}