import TextDisplay from '../../components/UI/TextDisplay/TextDisplay';
import NavigationItem from '../../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import Backdrop from '../UI/Backdrop/Backdrop';
import classes from '../../components/UI/TextDisplay/TextDisplay.module.css';
import spotify from '../../assets/images/spotify_grey.png';
import youtube from '../../assets/images/youtube_grey.png';
import tidal from '../../assets/images/tidal_grey.png';
import applemusic from '../../assets/images/applemusic_grey.png';
import soundcloud from '../../assets/images/soundcloud_grey.png';
import spotify_red from '../../assets/images/spotify_red.png';
import youtube_red from '../../assets/images/youtube_red.png';
import tidal_red from '../../assets/images/tidal_red.png';
import soundcloud_red from '../../assets/images/soundcloud_red.png';
import applemusic_red from '../../assets/images/applemusic_red.png';
import itunes from '../../assets/images/itunes_grey.png';
import itunes_red from '../../assets/images/itunes_red.png';
const Music = () => {

    const musicPlatforms = {
        "spotify": spotify,
        "youtube": youtube,
        "tidal": tidal,
        "applemusic": applemusic,
        "itunes": itunes,
        "soundcloud": soundcloud
    }

    const musicPlatforms_red = {
        "spotify": spotify_red,
        "youtube": youtube_red,
        "tidal": tidal_red,
        "applemusic": applemusic_red,
        "itunes": itunes_red,
        "soundcloud": soundcloud_red
    }


    const musicPlatforms_links = {
        "spotify": 'https://open.spotify.com/artist/0OefjbMJubDKsX94iSrun0?autoplay=true',
        "youtube": 'https://www.youtube.com/channel/UCpJQbvGwwHL5WanKKA8lrWQ',
        "tidal": 'https://tidal.com/browse/artist/8624002',
        "applemusic": 'https://music.apple.com/us/artist/petah-moodie/1218042091',
        "itunes": 'https://itunes.apple.com/us/artist/petah-moodie/id1218042091',
        "soundcloud": 'https://soundcloud.com/petahmoodie'
    }

    const content = < TextDisplay > {
        Object.keys(musicPlatforms).map((platform) => {
            return <div className={classes.gridItem} key={platform}>
                <NavigationItem
                    key={platform}
                    link={musicPlatforms_links[platform]}
                    image={musicPlatforms[platform]}
                    image2={musicPlatforms_red[platform]}
                    classes={classes}>
                </NavigationItem>
            </div>
        })
    } </ TextDisplay>

    return (
        <Backdrop
            show={true}
            clicked={true}>{content}
        </Backdrop>
    )
}

export default Music;